@import '../../../../styles/variables.scss';

.school-sign-up-form {
  position: absolute;
  max-width: 450px;
  height: 100%;
  left: 50%;
  top: 5%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  .header-input-field-div {
    margin: 16px 0 5px 0;
    .item-parent-div {
      width: 426px;
      .item-label-input {
        display: flex;
        justify-content: space-between;
        border: none;
        padding: 6px 0 6px 0;
        background-color: $item-label-input-background-color;
        white-space: normal;
        .link-position {
          float: right;
        }
      }
      .sign-up-input-field > div > div {
        width: 100%;
        border-radius: 0px;
        color: $black-color !important;
        vertical-align: middle;
        background-color: $default-white-color !important;
      }
      .sign-up-select-input > div > div > div {
        padding-top: 12px !important;
      }

      .sign-up-input-field > div > div > input {
        text-shadow: 0px 0px 1px $default-text-shadow-color;
        padding: 13px;
      }

      .error-text-component {
        margin: 0;
        background: $default-white-color;
        padding: 0 0 0 10px;
      }
    }

    .organization-type-bottom-fields {
      margin: 30px 10px 20px 0;
    }
    .bottom-fields {
      margin: 30px 10px 0 0;
    }

    .sign-up-button-parent-div {
      width: 400px;
      margin: 15px 0 0 15px;
      display: flex;
      border: none;
      padding: 6px 0 5px 0;
      background-color: transparent;
    }
    .back-button {
      left: -1% !important;
      background-color: $back-button-background-color !important;
      height: 60px !important;
      position: relative;
      width: 188px;
      border-radius: 0px !important;
      letter-spacing: 0.1em !important;
      margin: 0.25em 0 !important;
      border: none;
      transition: all 0.5s;
      color: $default-white-color;
      text-transform: capitalize;
      margin-right: 10px !important;
    }

    .login-button-styles {
      background-color: $input-label-text-color;
      color: $default-white-color;
      border: none;
      border-radius: 0px;
      cursor: pointer;
      position: relative;
      width: 188px;
      height: 60px !important;
      transition: all 0.5s;
      text-transform: capitalize;
      left: 25%;
      margin: 25px 0 5px 0;
    }
    .error-text-local-styles {
      margin-top: 20px;
    }
    .error-text-component {
      margin: 0;
      background: $default-white-color;
      padding: 0 0 0 10px;
    }
    .state-region-fields {
      display: flex;
      width: 95%;
    }
    .select-plan-fields {
      display: flex;
      width: 95%;
      .sign-up-select-input {
        display: flex;
        align-items: flex-start;
      }
    }
  }
  .next-up-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 115px;
    width: 70vw;
    max-width: 340px;
    padding: 10px;
  }
  .link-text-local-styles {
    height: 60px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 35px 0 3.5px 0;
    letter-spacing: 0.1rem !important;
    right: 15px;
  }
  .radio-buttons-label-style {
    position: relative !important;
    top: 25px !important;
  }
  .plan-label-fields {
    display: flex;
  }
  .plan-label {
    white-space: nowrap !important;
    margin: 12px 55px 0 0 !important;
  }
  .status-error-local-styles {
    margin: 10px 30px 0 0;
  }
  border: 0 !important;
}
