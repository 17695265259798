@import './Global.scss';
@import './GlobalDefaults.scss';
@import './variables.scss';

path {
  transition: fill 0.4s ease;
}

/*
App Container styles
*/
.app-container {
  display: grid;
  grid-template-columns: 250px auto;
  height: 100vh;
  width: 100%;
}

.contract-container {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-rows: 80px;
  overflow: hidden;
}

.content-container {
  flex: 1;
  overflow-y: auto;
}

.h100-w100 {
  height: 100%;
  width: 100%;
}

.flex-bar {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-header {
  font-size: $legend-header-font-size;
  color: $legend-header-color;
  border: 0;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.login-input-field {
  width: 100%;
  font-size: $text-secondary-font-size;
  font-family: $input-field-font-family;
  color: $black-color;
  padding-left: 14px;
  padding-right: 8px;
  vertical-align: middle;
  border: 1px solid $input-border-color;
  background-color: $default-white-color !important;
}

.input-label {
  color: $input-label-text-color;
  margin-left: 14px;
  font-size: $input-label-font-size;
  font-weight: 700;
  display: border-box;
}

.link {
  border: 0px;
  color: $reference-link-text-color;
  cursor: pointer;
  background-color: $default-white-color;
  text-decoration: underline;
  font-size: $link-font-size;
  margin-right: 12px;
  font-family: $link-font-family;
  font-weight: 600;
}

.side-bar-view {
  position: fixed;
  width: 75%;
  left: 259px;
  top: 0;
  height: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.submit-button {
  font-weight: bold;
  left: 0% !important;
  background-color: $input-label-text-color;
  height: 60px !important;
  position: relative;
  border-radius: 0px !important;
  font-size: $submit-button-font-size;
  letter-spacing: $submit-button-letter-spacing;
  margin: 0.25em 0 !important;
  border: none;
  transition: all 0.5s;
  color: $default-white-color !important;
}

.input-field-styles {
  font-size: $text-secondary-font-size;
  font-family: $input-field-font-family;
}

.login-button-font-styles {
  font-size: $submit-button-font-size;
  letter-spacing: $submit-button-letter-spacing;
  font-style: $submit-button-font-weight;
}

.back-button-font-styles {
  font-size: $back-button-font-size !important;
  font-style: $submit-button-font-weight;
  letter-spacing: $submit-button-letter-spacing;
}

.link-text {
  text-decoration: $text-link-decoration !important;
  font-size: $text-link-font-size !important;
  font-weight: $text-link-font-weight !important;
  color: $text-link-color;
  text-shadow: 0px 0px 0px $text-link-color;
}

.contract-forgot-password-link {
  text-decoration: $text-link-decoration !important;
  font-size: $contract-text-link-font-size !important;
  font-weight: $text-link-font-weight !important;
  color: $contract-text-link-color;
  text-shadow: 0px 0px 0px $text-link-color;
}

// Common style for all error texts
.error-text {
  transition: all 500ms ease-out;
  border: none;
  text-align: center;
  background-color: $login-error-text-background-color;
  -webkit-box-shadow: inset 7px 0 0 $login-error-text-label;
  box-shadow: inset 7px 0 0 $login-error-text-label;
  padding: 10px 0 5px 0;
  white-space: normal;
  height: 40px;
  margin-bottom: 10px;
}

.radio-label-font-styles {
  white-space: nowrap;
  font-size: $input-label-font-size;
  font-weight: $app-secondary-font-weight;
}

.close-icon {
  cursor: default;
}

.input-field-styles > div > input:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

.input-field-styles > div > input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

.input-field-styles > div > svg {
  color: $input-label-text-color !important;
}

.close-icon-visibility {
  visibility: hidden !important;
}

.status-error {
  width: inherit;
  text-align: center;
  color: #db3e7b;
  word-break: break-word;
}

.select-component {
  font-family: $select-component-font-family;
}

.list-item {
  color: $black-color !important;
}

// data grid buttons
.grid-approve-button-color {
  button {
    background-color: $grid-approve-button-background-color !important;
    border-radius: 0;
  }
}

// version-2 approve button
.grid-approve-updated-version-button-color {
  button {
    background-color: $grid-approve-button-background-color !important;
    border-radius: 0;
    font-size: $input-label-font-size !important;
    background-image: linear-gradient(
      to bottom,
      $grid-approve-button-background-color-gradient,
      transparent
    );
  }
}

// version-2 deny button
.grid-deny-updated-version-button-color {
  button {
    background-color: $grid-trash-button-background-color !important;
    border-radius: 0;
    font-size: $input-label-font-size !important;
    background-image: linear-gradient(
      to bottom,
      $grid-deny-button-background-color-gradient,
      transparent
    );
  }
}

.grid-trash-button-color {
  button {
    background-color: $grid-trash-button-background-color !important;
    border-radius: 0;
  }
}

.data-grid-label-green-color {
  color: $text-green-color !important;
  font-family: $data-grid-font-family;
  font-size: $span-page-button-font-size;
}

// mui datagrid styles
.parent-div {
  .data-grid {
    height: 90%;

    .MuiDataGrid-columnHeaders {
      width: 100%;
      border-bottom-color: $data-grid-column-header-border-bottom-color;
      display: inline-flex;
      background-color: $data-grid-column-header-background;
      min-height: 40px !important;
      max-height: 40px !important;
      font-size: 10px;
      font-weight: bold;
      color: $data-grid-column-header-color;
      border-right: 0px;
      border-radius: 0px !important;
      padding: 15px 15px 10px 15px;
      font-family: $data-grid-font-family;

      .MuiDataGrid-columnHeaderTitle {
        font-family: $data-grid-font-family;
        opacity: 0.8;
      }

      .MuiDataGrid-iconSeparator {
        display: none;
      }

      .MuiDataGrid-menuIcon {
        button {
          svg {
            display: none;
          }
        }
      }
    }

    .MuiDataGrid-virtualScroller {
      margin-top: 40px !important;
    }

    .MuiDataGrid-cellContent {
      margin-left: 10px;
      margin-top: 0px;
      font-weight: normal;
      letter-spacing: 0.5px;
      color: $black-color;
      font-family: $data-grid-font-family;
      font-size: $data-grid-cell-content-text-font-size;
    }

    .MuiTablePagination-root {
      p {
        width: 94px;
        font-size: 14px;
        height: 30px;
        font-weight: bold;
        border: 0px;
        color: #767f8b !important;
        text-align: center;
        font-family: $data-grid-font-family;
      }
    }

    .MuiDataGrid-footerContainer {
      display: none;
    }

    .MuiDataGrid-row {
      width: 100%;
    }
  }
}

// custom pagination styles

.custom-pagination {
  border-top: 1px solid $data-grid-border-top;

  .page-number {
    display: flex;
    align-items: center;

    span {
      float: left;
      margin: 0 20px;
      color: $data-grid-cell-content-text-color !important;
      font-weight: bold;
      font-size: $span-page-button-font-size;
      filter: brightness(1.2);
    }
  }

  .pagination-div {
    font-family: $data-grid-font-family;

    .first-and-last-button-styles {
      width: 55px;
      background-color: $extreme-button-color;
      border-radius: 5px;
      height: 32px;
      font-size: $pagination-button-font-size !important;
      font-weight: bold;
      color: $data-grid-cell-content-text-color;
      border: 1px solid $data-grid-cell-content-text-color;
      text-transform: capitalize;
      margin-right: 3px;
      width: 55px;
      letter-spacing: 0 !important;
      box-shadow: none !important;
      padding: 1px 6px !important;
    }

    .previous-and-next-button-styles {
      background-color: $next-button-background;
      border-radius: 5px;
      height: 32px;
      font-weight: bold;
      font-size: $pagination-button-font-size !important;
      color: $default-white-color;
      border: $previous-and-next-button-style-border;
      text-transform: capitalize;
      margin-right: 3px;
      width: 70px;
      letter-spacing: 0 !important;
      padding: 1px 6px !important;
      box-shadow: none !important;
    }

    .previous-and-next-button-styles-gradient {
      background-image: linear-gradient(
        to bottom,
        $custom-pagination-previous-next-buttons-gradient,
        transparent
      );
    }
  }

  .Mui-disabled {
    color: $disabled-button-text-color !important;
    box-shadow: none;
    background-color: $disabled-button-background-color !important;
    border: 0px !important;
    background-image: none !important;
  }
}

.leaderboard-approve-text {
  color: $grid-approve-button-background-color;
  font-size: $input-label-font-size;
  font-family: $data-grid-font-family;
  text-shadow: 0 0 $grid-approve-button-background-color;
}

.leaderboard-deny-text {
  color: $grid-trash-button-background-color;
  font-size: $input-label-font-size;
  font-family: $data-grid-font-family;
  text-shadow: 0 0 $grid-trash-button-background-color;
}

.search-tabs-parent-div {
  display: inline-flex;
  width: 100%;

  button {
    text-transform: capitalize;
    color: $reference-link-text-color;
    margin-right: 2px;
    line-height: 1.42857143;
    border-radius: 4px 4px 0 0;
    font-size: 16px;
  }
}

.no-of-users {
  color: $black-color;
  font-size: $text-secondary-font-size;
}

.suspend-button-styles {
  color: $default-white-color !important;
  background-color: $grid-trash-button-background-color !important;
  font-weight: bold;
  float: right !important;
  min-width: 140px !important;
  height: 40px !important;
  border-radius: 0px !important;
  padding: 1px 6px !important;
  margin: 10px 20px 0 0 !important;
}

.un-suspend-button-styles {
  color: $default-white-color !important;
  background-color: $grid-approve-button-background-color !important;
  font-weight: bold;
  float: right !important;
  min-width: 140px !important;
  height: 40px !important;
  border-radius: 0px !important;
  padding: 1px 6px !important;
  margin: 10px 20px 0 0 !important;
}

.add-exercise-button-styles {
  color: $default-white-color !important;
  background-color: $grid-approve-button-background-color !important;
  font-weight: bold;
  float: right !important;
  min-width: 140px !important;
  height: 40px !important;
  border-radius: 0px !important;
  padding: 1px 6px !important;
  margin: 10px 20px 0 0 !important;
  font-size: $text-secondary-font-size !important;
  background-image: linear-gradient(
    to bottom,
    $grid-approve-button-background-color-gradient,
    transparent
  );
}

.create-client-challenge-button-styles {
  color: $default-white-color !important;
  background-color: $grid-approve-button-background-color !important;
  font-weight: bold;
  float: right !important;
  min-width: 140px !important;
  height: 40px !important;
  border-radius: 0px !important;
  padding: 1px 6px !important;
  margin: 10px 20px 0 0 !important;
  font-size: $text-secondary-font-size !important;
  background-image: linear-gradient(
    to bottom,
    $grid-approve-button-background-color-gradient,
    transparent
  );
}

.add-button-styles {
  color: $default-white-color !important;
  background-color: $grid-approve-button-background-color !important;
  font-weight: bold;
  float: right !important;
  min-width: 65px !important;
  height: 40px !important;
  border-radius: 0px !important;
  padding: 1px 6px !important;
  margin: 10px 20px 0 0 !important;
  background-image: linear-gradient(
    to bottom,
    $grid-approve-button-background-color-gradient,
    transparent
  );
}

.date-cum-string-element {
  color: $input-label-text-color !important;
  font-size: 14px !important;
  cursor: pointer !important;
  font-family: $data-grid-font-family;
}

.dialog-title-class {
  color: $dialog-title-color !important;
  font-size: $dialog-title-font-size !important;
}

.expiry-date-field {
  color: $input-label-text-color !important;
  font-size: $input-label-font-size !important;
  white-space: nowrap;
  margin-top: 20px;
}

.input-text-label-field {
  color: $input-label-text-color !important;
  font-size: $input-label-font-size !important;
  white-space: nowrap;
  margin-top: 20px;
  text-shadow: 0px 0px 0px $input-label-text-color !important;
}

.dialog-close-button {
  color: $default-white-color !important;
  background-color: $dialog-cancel-button-background-color !important;
  font-weight: bold !important;
  width: 50% !important;
  height: 60px !important;
  border: 0px !important;
  border-radius: 0 !important;
  text-transform: capitalize !important;
  font-size: $text-secondary-font-size !important;
}

.dialog-save-button {
  color: $default-white-color !important;
  background-color: $button-background-color !important;
  font-weight: bold !important;
  width: 50% !important;
  height: 60px !important;
  border: 0px !important;
  border-radius: 0 !important;
  text-transform: capitalize !important;
  font-size: $text-secondary-font-size !important;
}

.dialog-confirm-button {
  color: $default-white-color !important;
  background-color: $grid-approve-button-background-color !important;
  font-weight: bold !important;
  width: 50% !important;
  height: 60px !important;
  border: 0px !important;
  border-radius: 0 !important;
  text-transform: capitalize !important;
  font-size: $text-secondary-font-size !important;
}

.add-button-styles {
  color: $default-white-color !important;
  background-color: $grid-approve-button-background-color !important;
  font-weight: bold;
}

.delete-button-styles {
  color: $default-white-color !important;
  background-color: $grid-trash-button-background-color !important;
  font-weight: bold;
  background-image: linear-gradient(
    to bottom,
    $grid-deny-button-background-color-gradient,
    transparent
  );
}

.dialog-label-field {
  color: $input-label-text-color !important;
  font-size: $input-label-font-size !important;
  font-weight: 600;
}

.dialog-button-class {
  font-size: $text-secondary-font-size !important;
}

.dialog-delete-button {
  color: $default-white-color !important;
  background-color: $grid-trash-button-background-color !important;
  font-weight: bold !important;
  width: 50% !important;
  height: 60px !important;
  border: 0px !important;
  border-radius: 0 !important;
  text-transform: capitalize !important;
}

.date-cum-string-element-with-no-color {
  font-size: 14px !important;
  cursor: pointer !important;
  font-family: $data-grid-font-family;
}

// Client school name dropdown styles

.menu-drop-down-component {
  .school-menu-button-component {
    svg {
      color: $svg-icon-color;
    }
  }
}

.school-styled-menu-component {
  .MuiPaper-root {
    li {
      color: $svg-icon-color !important;
    }

    .Mui-selected {
      color: $black-color !important;
    }
  }
}

.client-school-code-class-parent-div {
  .client-school-code-class {
    font-size: 11.5px;
    color: $school-code-text-color;
  }
}

.check-box-rounded-icon-styles {
  color: $white-background-color;
}

.three-dots-element {
  font-size: 24px !important;
  position: relative;
  font-weight: 500 !important;
  cursor: pointer;
}

// client challenges screen styles
.tab-components-client-challenges-local-styles {
  div {
    div {
      button {
        font-size: $text-secondary-font-size !important;
      }
    }
  }
}

// Tab component styles
.tab-components-styles {
  button {
    font-size: $text-secondary-font-size !important;
  }
}

// Video component styles
.video-component {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}

//teams-link-text
.teams-link-text {
  text-decoration: $text-link-decoration !important;
  font-size: $teams-text-link-font-size !important;
  font-weight: $text-link-font-weight !important;
  color: $text-link-color;
  text-shadow: 0px 0px 0px $text-link-color;
}

//circular-progress-styles
.circular-progress-color {
  color: $default-white-color !important;
}

.close-button-class {
  z-index: 9999;
  padding: 3px 10px 3px 10px !important;
  position: relative !important;
  top: 0;
  left: 0px;
  border-radius: 0px;
  font-size: 16px;
  color: $default-white-color !important;
  background-color: $video-button-background-color !important;
  border-radius: 0px !important;
  font-family: $video-font-family !important;
  display: flex;
  max-height: 30px;
  left: -66px;
}

.avatar-tpotilp-class {
  cursor: pointer;
}

.video-element-styles {
  border: 15px solid $white-background-color;
}

.org-label-element {
  font-size: $text-secondary-font-size;
}

.video-icon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

// Admin contract tab styles
.admin-contracts-class {
  .MuiTab-root {
    font-size: $text-secondary-font-size;
  }
}

.review-button {
  color: $default-black-color !important;
  text-transform: capitalize !important;
  border: 0.5px solid $reference-link-text-color !important;
}

//Details and comment section styles
.details-comments-section-class {
  background-color: $details-comments-section-background-color;
}

.react-drawer-component {
  .details-comments-section-class {
    .Mui-selected {
      color: $default-white-color !important;
    }

    .MuiTab-root {
      font-size: $text-secondary-font-size !important;
    }
  }
}

// contract-details-styles
.contract-details-class {
  background-color: $contract-details-background-color;

  .contract-details-list {
    background-color: $contract-details-list-background-color;

    .contract-details-list-name {
      color: $white-background-color;
      font-weight: 600;
    }
    .contract-email-styles {
      color: $contract-details-list-location-color;
      font-size: $input-label-font-size;
      margin: 3px 0;
    }
    .contract-details-list-location {
      color: $contract-details-list-location-color;
      font-size: $input-label-font-size;
    }

    .contract-details-list-user {
      color: $contract-details-list-location-color;
      font-size: $input-label-font-size;
    }
  }
}

.contract-amount {
  font-size: $text-secondary-font-size !important;
  color: $white-background-color;
}

.contract-title-class {
  font-size: $input-text-medium-font-size !important;
  color: $white-background-color;
}

//challenge-details-styles
.challenge-details-class {
  background-color: $contract-details-background-color;

  .challenges-content-class {
    background-color: $white-background-color;
  }

  .challenges-bottom-details {
    .challenges-bottom-details-parent-div {
      .challenges-bottom-details-text {
        color: $default-white-color;
        font-size: $text-tertiary-font-size;
        text-shadow: 0px 0px $default-white-color;
      }

      .challenges-bottom-details-text-value {
        color: $default-white-color;
        font-size: $text-tertiary-font-size;
        max-width: 120px;
        word-wrap: break-word;
      }
    }
  }
}

//contract-payment-details
.contract-payment-details,
.paid-contract-payment-details {
  background-color: $contract-details-background-color !important;
}

.payment-title-class {
  font-size: $input-text-medium-font-size !important;
  color: $white-background-color;
}

.contract-payments-divider {
  background-color: $contracts-payments-divider-color !important;
}

.payment-fee-class-text-label {
  font-size: $input-text-medium-font-size !important;
  color: $white-background-color !important;
}

.payment-fee-class-text-amount {
  font-size: $input-text-medium-font-size !important;
  color: $white-background-color !important;
}

.contract-payments-sub-section-text {
  font-size: $text-secondary-font-size !important;
  color: $white-background-color !important;
}

.contract-payments-sub-section-text-description {
  font-size: $input-label-font-size !important;
  color: $border-primary-color !important;
  max-width: 286px;
  font-weight: 400;
}

// Video component styles
.video-component {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}

//client-contract-styles
.client-contract-details {
  background-color: $client-contract-details-background;
  // margin: 20px 20px 0 0;
}

.client-contract-details-title-styles {
  font-size: $text-secondary-font-size;
  color: $default-white-color;
}

.client-contract-details-title-tag {
  font-size: $input-label-font-size;
  color: $contract-details-list-location-color;
}

.client-contract-details-video-thumbnail {
  background-color: $default-white-color;
}

.avatar-tootilp-class {
  cursor: pointer;
}

.client-contract-details-video-thumbnail-content {
  font-size: $text-tertiary-font-size;
  color: $default-white-color;
}

.view-details-button {
  background-color: $default-white-color !important;
  border: 1px solid $black-color !important;
  color: $black-color !important;
  text-transform: capitalize !important;
}

//teams-link-text
.teams-link-text {
  text-decoration: $text-link-decoration !important;
  font-size: $teams-text-link-font-size !important;
  font-weight: $text-link-font-weight !important;
  color: $text-link-color;
  text-shadow: 0px 0px 0px $text-link-color;
}

//circular-progress-styles
.circular-progress-color {
  color: $default-white-color !important;
}

.close-button-class {
  z-index: 9999;
  padding: 3px 10px 3px 10px !important;
  position: relative !important;
  top: 0;
  left: 0px;
  border-radius: 0px;
  font-size: 16px;
  color: $default-white-color !important;
  background-color: $video-button-background-color !important;
  border-radius: 0px !important;
  font-family: $video-font-family !important;
  display: flex;
  max-height: 30px;
  left: -66px;
}

.avatar-tpotilp-class {
  cursor: pointer;
}

.video-element-styles {
  border: 15px solid $white-background-color;
  width: 640px;
  height: 360px;
  background-color: $black-color;
}

.org-label-element {
  font-size: $text-secondary-font-size;
}

.video-icon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.view-details-button {
  color: $default-white-color;
  background-color: $default-white-color !important;
  border: 1px solid $black-color !important;
  color: $black-color !important;
  text-transform: capitalize !important;
}

.client-contract-details-parent-div {
  margin: 0 20px 20px 20px;
  position: relative;
  top: 20px;
}

.client-contract-details-video-thumbnail {
  color: $default-white-color;
  background-color: $default-white-color;
}

// disabled-fields

.login-input-disabled-field > div > div {
  color: $black-color !important;
  background-color: $text-disabled-background-color !important;
}

.login-input-disabled-field > div > div > input {
  text-shadow: 0px 0px 1px $default-text-shadow-color;
}

//text-area-styles
.text-area-input-field-styles {
  font-size: $text-area-font-size !important;
  font-family: $text-area-font-family !important;
  padding: 16.5px 14px;
}

//status-button
.client-contract-details-parent-div .status-button {
  font-size: $text-tertiary-font-size !important;
  color: $default-white-color !important;
  background-color: $contract-text-link-color !important;
  opacity: 80%;
}

//apply-contracts-screen
.apply-contracts-screen {
  background-color: $font-primary-color !important;

  .contract-details {
    background-color: $client-contract-details-section-background !important;
  }
}

.divider-styles {
  border-color: $divider-color !important;
  margin: 0 20px !important;
}

.dashboard-link-text-styles {
  color: $default-white-color !important;
  font-size: $text-secondary-font-size !important;
}

//apply-contracts-screen
.apply-contracts-screen .client-contract-details-parent-div .open-status {
  background-color: $apply-contracts-screen-background-color !important;
}

.dashboard-contracts-list .details-comments-section-class .my-contracts-label {
  color: $default-white-color;
  font-size: $input-text-medium-font-size;
}

.contract-blank-screen-styles {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $default-white-color;
  font-size: $input-text-medium-font-size;
  width: 100%;
  height: 100%;
}

.client-contract-empty-details-parent-div {
  width: 100%;
  height: 100%;
}

.video-upload-class {
  font-size: $text-secondary-font-size;
  color: $black-color;
  font-family: $data-grid-font-family !important;
}

.MuiAutocomplete-root {
  width: inherit !important;
}

// autocomplete-styles
.autocomplete-dropdown-parent-class {
  display: flex;
  .autocomplete-dropdown-profile-image-class {
    width: 20px;
    height: 20px;
  }
  .athlete-profile-image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  .autocomplete-dropdown-label-class {
    display: flex;
    flex-direction: column;
    margin: 0 15px 0 20px;

    .autocomplete-username-class {
      width: 100%;
      overflow: hidden;
      font-size: $auto-complete-font-text-size;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $font-primary-color;
      font-weight: 400;
      line-height: 1.125;
      letter-spacing: 0.00938rem;
    }

    .autocomplete-email-class {
      width: 100%;
      overflow: hidden;
      font-size: $auto-complete-email-font-text-size;
      white-space: nowrap;
      text-overflow: ellipsis;
      opacity: 0.6;
    }
  }
}

.MuiAutocomplete-option {
  width: 100%;
  font-size: $font-heading-size;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $font-primary-color;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: 0.00938rem;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
}

.MuiAutocomplete-paper {
  overflow: auto !important;
}

// award-contract-header-styles
.award-contract-header {
  display: flex;

  .award-contract-header-buttons {
    .dismiss-button-local-styles {
      background-color: $dismiss-button-color !important;
      font-size: $dismiss-button-font-size !important;
    }

    .award-button-local-styles {
      background-color: $award-contract-button-color !important;
      font-size: $award-contract-font-size !important;
    }
  }
}

//contract-conversation-styles
.contract-conversation {
  .submit-button-class {
    font-size: $input-label-font-size;
    color: $default-white-color;
    background-color: $contract-text-link-color;
  }

  .add-comment-text-class {
    font-size: 12px;
    color: $add-comment-color;
  }

  .date-formatter-class {
    color: $default-white-color;
    font-size: $input-label-font-size;
  }
}

//contract-updates-styles
.contract-updates {
  width: 472px;
  height: 30px;
  font-size: $input-label-font-size;
  background-color: $contract-updates-background-color;
  color: $contract-updates-color;
  // font-family: $contract-updates-font;
  text-align: center;
  opacity: 0.7;
  border-radius: 6px;
}

.contract-challenge-video {
  .challenged-created {
    font-size: $text-tertiary-font-size;
  }

  .challenged-title {
    font-size: $input-label-font-size;
  }

  .challenged-description {
    font-size: $input-label-font-size;
  }

  .challenged-button-components {
    .challenge-decline-button-class {
      font-size: $input-label-font-size;
    }

    .challenge-approve-button-class {
      font-size: $input-label-font-size;
    }
  }
}

// challenge -contract-dialog-styles
.challenge-contract-dialog {
  .prize-value-field {
    .prize-value-label-field {
      font-size: $text-tertiary-font-size;
    }

    .prize-value-input-field {
      background-color: $prize-value-text-field-background !important;
    }
  }

  .challenge-title-local-styles-class {
    font-size: $input-text-medium-font-size !important;
  }

  .challenge-video-content {
    font-size: $input-label-font-size;
  }

  .decline-challenge-video-content {
    font-size: $text-primary-font-size;
  }

  .challenge-video-details {
    .challenge-dialog-title {
      font-size: $input-label-font-size;
    }

    .challenge-dialog-description {
      font-size: $input-label-font-size;
    }

    .challenge-dialog-details {
      .challenge-dialog-details-label {
        font-size: $text-tertiary-font-size;
      }

      .challenge-dialog-details-value {
        font-size: $text-tertiary-font-size;
      }
    }
  }

  .challenge-dialog-actions-class {
    .challenge-close-button-local-styles {
      font-size: $input-label-font-size !important;
    }

    .challenge-confirm-button-local-styles {
      font-size: $input-label-font-size !important;
    }
  }
}

// contract-success-payment-card-styles

.contract-success-payment-card {
  .contract-success-payment-card-title-header {
    font-size: $title-header-font-size;
  }

  .contract-success-payment-card-contract-status {
    font-size: $text-primary-font-size;
  }

  .contract-success-payment-card-payment-status {
    font-size: $input-label-font-size;
  }

  .athlete-amount-distribution {
    .fee-label-class {
      .athlete-fee-label-class {
        font-size: $text-secondary-font-size;
      }

      .athlete-fee-transfer-text-label-class {
        font-size: $text-secondary-font-size;
      }
    }

    .fee-amount-class {
      font-size: $text-secondary-font-size;
    }
  }

  .service-amount-distribution {
    .fee-label-class {
      .service-fee-distribution-label-class {
        font-size: $input-label-font-size;
      }

      .service-fee-label-class {
        font-size: $text-secondary-font-size;
      }

      .service-fee-transfer-text-label-class {
        font-size: $text-secondary-font-size;
      }
    }

    .fee-amount-class {
      font-size: $text-secondary-font-size;
    }
  }
}

.MuiPickersCalendarHeader-label {
  font-family: $text-area-font-family !important;
}

// visibility-icon-styles
.visibility-icon {
  width: 0.8em !important;
  height: 0.8em !important;
}

// tooltip-styles
.MuiTooltip-popper {
  div {
    font-family: $text-area-font-family !important;
  }
}

//dot-icon-styles
.dot-icon {
  color: $tab-header-color;
}

// Common styles for ThumbNailComponent image

.gridtable-thumbnail-class {
  padding-top: 2px;
  cursor: pointer;

      div {
          div {
              position: relative;
              top: 2px;
              img:first-child {
                  width: 84px;
                  height: 50px;
                  border: 2px solid $thumb-nail-border-color;
                  cursor: pointer;
                  position: relative;
                  margin: auto;
              }
          }
      }
}

// challenge-submitted-video-styles 
.challenge-image-container-styles {
  width: 404px;
  height: fit-content;
  background-color: $contract-details-background-color;
  margin: 0 5px;
  .challenge-details-label-class-styles {
   font-size: 24px;
   color: $default-white-color;
   padding: 10px 20px;
 }
  .challenge-details-video {
   width: 92.5%;
   height: 226px;
   margin: 15px 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  .image-container {
   position: relative;
   height: 100%;
   width: 100%;
   overflow: hidden;
   .image-styles {
     width: 100%;
     overflow: hidden;
     height: 100%;
   }
}
 .play-icon {
   color: $default-white-color;
   position: relative;
   bottom: 45%;
 }
}
.avatar-class {
  img {
      cursor: pointer;
      width: 100% !important;
      height: 100% !important;
  }
}
}

// styles for userhandle
.userhandle {
  font-size: $input-label-font-size;
  color: $tab-indicator-color;
}