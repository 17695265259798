$app-side-bg: #e6ecf0;
$app-primary-color: #2884ff;
$app-secondary-color: #1ab23d;
$text-green-color: green;
// defining screen sizes for use in media queries
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1600px;
$screen-xxl: 1920px;

// font sizes defaults
$text-primary-font-size: 18px;
$text-secondary-font-size: 16px;
$text-tertiary-font-size: 12px;
$font-heading-size: 1.25rem;
$auto-complete-font-text-size: 1.1rem;
$auto-complete-email-font-text-size: 0.9rem;
$input-label-font-size: 14px;
$legend-header-font-size: 1.7em;
$back-button-font-size: 1.1em;
$teams-text-link-font-size: 14px;
$app-primary-font-weight: 1000;
$app-secondary-font-weight: 700;
$app-tertiary-font-weight: 400;
$app_primary_font_regular: RobotoRegular;
$semi-light-green-color: rgba(68, 194, 124, 0.3);

$app-error-color: red;
$app-success-color: green;

$app-unlock-color: #2884ff;

$text-error-color: $app-error-color;
$text-success-color: $app-success-color;

$text-font-medium: RobotoMedium;
$text-font-bold: RobotoBold;
$text-font-semi-bold: SFProSemiBold;
$text-font-regular: RobotoRegular;
$text-font-roboto-bold: RobotoBold;
$text-font-roboto-medium: RobotoMedium;
$text-font-roboto-regular: RobotoRegular;
$text-font-rockwell-bold: RockwellBold;
$text-font-rockwell-regular: RockwellRegular;

$font-primary-color: #241a2e;
$view-details-button-text-shadow: #241a2ea3;
$font-secondary-color: #54585b;
$font-tertiary-color: #b1b4be;
$font-label-color: #9d9b9b;
$font-labels-color: #4b4747;

$background-primary-color: #f5f4f6;
$background-secondary-color: #252dbc;

$background-react-calender-color: #1a73e8;

$ink-bar-orange-color: #f09722;
$ink-bar-green-color: $app-secondary-color;

$border-primary-color: #d5d9e3;

$white-background-color: #fbfbfb;

$default-white-color: #ffffff;

// $default-black-color: #000000;
$default-black-color: #151e24;

$placeholder-text-color: #9d9d9d;

$badge-color: #da5a30;

$tag-color: #0051d4;

$care-green-color: #1ab2a5;

$light-green-color: #62d27c;

$onboarding-header-color: #003891;

$search-bar-background-color: #e6e6e6;

$table-columns-text-color: #231b2d;

$advisor-dashboard-right-section: #002258;

$invite-client-left-section: #cdefe7;

$light-grey-color: #ececec;

$grey-color-1: #8f8f90;

$grey-color-2: #eeeff2;

$grey-color-3: #f9fafc;

$body-background-color: #f4f6f8;

$profile-horizontal-line-background-color: #cdcad0;

$admin-bill-pay-amount-color: #da5a30;

$card-border-color: #c1c1c1;
$divider-color: #c1c1c1;
$divider-dark-color: #a0a0a0;
$active-card-background-color: #e6f1ff;
$active-card-border-color: #74b0ff;
$active-sub-heading-color: #5f5f5f;

$filter-background-color: #ededed;
$filter-chip-background-color: rgba(26, 119, 255, 0.1);
$button-background-color: #2884ff;
$header-background-color: #f9fafc;
$popup-address-color: #777777;
$button-border-color: #2884ff;

$card-border-color: #a7cdff;
$border-color: #c1c1c1;
$border-shadow: #00000029;
$border-shadow-card: #bfd1e0;
$sub-title-text-color: #000000;
$card-background-color: #d6fff6;
$card-border-color: #44a791;
$location-card-border-color: #93beff;
$patient-card-background-color: #e4eef6;
$patient-card-border-color: #a3bbcf;
$item-label-input-background-color: transparent;

// payment screen
$payment-details-border-color: #dddddd;
$payment-details-background-color: #f8f8f8;
$payment-success-border-color: #70a6ec;
$payment-success-background-color: #eff3f8;
$payment-success-email-background-color: #f5eed2b0;

$box-shadow-color: #0000001a;
$box-background-color: #fcfcfc;
$default-red-color: #db5957;
$black-color: #000000;

// dashboard screen
$dashboard-salutation-color: #444;

// message screen
$message-date-color: #444444;
$alert-helper-text-color: #ffe6e6;

// disable button
$button-disable-date-color: rgba(0, 0, 0, 0.26);

// calendar now date color
$calendar-now-date-color: #e8eaed;

// login-form colors
$input-border-color: #ced5df;
$legend-header-color: #2b2d32;
$input-label-text-color: #378be1;
$back-button-background-color: #bac5cf;

//font-family
$input-text-font-family: 'SF Pro Display Regular';
$data-grid-font-family: 'SFProRegular';
$input-field-font-family: 'SF Pro Display Regular' !important;
$input-field-semi-bold-font-family: 'SourceSansPro-SemiBold';
$sans-serif-font-family: sans-serif;

//link
$reference-link-text-color: #747a7f;

//error-test
$login-error-text-label: #b11a1a;
$login-error-text-background-color: #fbfbfb;
$link-font-size: 12.5px;
$link-font-family: 'Roboto-Regular';

//text-shadow
$default-text-shadow-color: #888888;

//submit-button
$submit-button-font-size: 1.1em !important;
$submit-button-letter-spacing: 0.1em !important;
$submit-button-font-weight: bold;

//text-link
$text-link-font-size: 15px;
$contract-text-link-font-size: 16px;
$text-link-font-weight: normal;
$text-link-color: #378be1 !important;
$contract-text-link-color: #4386f1 !important;
$text-link-decoration: underline;

//data-grid-styles
$data-grid-column-header-border-bottom-color: #ced5df;
$data-grid-column-header-background: #d5dfee;
$data-grid-column-header-color: #777f8c;
$data-grid-cell-content-text-color: #767f8b;
$data-grid-border-top: #ddd;
$data-grid-cell-content-text-font-size: 14px;
$data-grid-font-family: $data-grid-font-family !important;
$pagination-button-font-size: 14px;
$extreme-button-color: #f0f5fc;
$span-page-button-font-size: 13px;
$next-button-background: #0088d4;
$grid-approve-button-background-color: #11b683;
$grid-approve-button-background-color-gradient: rgb(110 232 195 / 46%);
$grid-deny-button-background-color-gradient: #ff848091;
$custom-pagination-previous-next-buttons-gradient: #6fc8fa3b;
$grid-trash-button-background-color: #e41711;
$previous-and-next-button-style-border: 1px solid #ced5df;
$leaderboard-athletes-text-color: rgba(0, 0, 0, 0.85);
//select-component
$select-component-font-family: $data-grid-font-family !important;

//dialog-styles
$dialog-title-color: #2b2d32;
$dialog-title-font-size: 1.8em;
$dialog-cancel-button-background-color: #cdd5df;

//disabled-button-styles
$disabled-button-text-color: rgba(0, 0, 0, 0.26);
$disabled-button-background-color: rgba(0, 0, 0, 0.12);

//exercises-table-styles
$exercises-label-text-color: rgba(0, 0, 0, 0.8);

//client-menu-drop-down-styles
$svg-icon-color: #808080;
$mui-list-item-selected-background-color: rgb(0 45 139 / 18%);
$school-code-text-color: #a9a9a9d4;

//thumbnail-border
$thumb-nail-border-color: lightgray;

//video-player-styles
$video-font-family: 'videogular';
$video-button-background-color: #5bc0de;
$client-contract-details-section-background: #48424d;
//Details and comment section styles
$details-comments-section-background-color: rgb(36, 26, 46);

// contract-details-styles
$contract-details-background-color: #252b3a;
$input-text-bold-font-family: 'SF Pro Display Bold';
$input-text-medium-font-family: 'SF Pro Display Medium';
$input-text-medium-font-size: 24px;
$contract-details-list-background-color: #40485a;
$contract-details-list-location-color: #a9adb3;
$contracts-payments-divider-color: #40485a;

//client-contract-details-styles
$client-contract-details-background: #48424d;
//thumbnail-border
$thumb-nail-border-color: lightgray;

//video-player-styles
$video-font-family: 'videogular';
$video-button-background-color: #5bc0de;

//disabled-fields
$text-disabled-background-color: #d3d3d37a;

//error-text-styles
$error-text-color: #eb0014;

//client-tab-styles
$tab-color: #7c8490;
$tab-indicator-color: #4386f1;
$confirm-button-disabled-color: #a9a9a9;

//text-area-styles
$text-area-font-size: 1rem;
$text-area-font-family: 'SFProRegular';

//submissions-class
$submission-class-backgroun-color: #1f1c22;

//contract-dashboard-styles
$contract-dashboard-tabs-background-color: #ff0000;

//divider-styles
$divider-color: #e7ebf03d;

//apply-contracts-screen-styles
$apply-contracts-screen-background-color: #252b3a;

//award-contract-header-styles
$dismiss-button-color: #7c8490;
$dismiss-button-font-size: 14px;
$award-contract-button-color: #44c27c;
$award-contract-font-size: 14px;
$tab-header-color: #e4120c;
$award-contract-title-header-font-size: 1.6em;
$add-comment-color: #c9c9c9;
$contract-applicant-profile: #f1f4f9;

//contract-updates-styles
$contract-updates-background-color: #284e67;
$contract-updates-color: #ffffff;
$contract-updates-font: 'SF Pro Display Medium Italic';
$contract-portal-background-color: #241a2e;
$applicant-profile-key: #727272;

//challenge-video-styles
$challenge-video-details-background: #efefef;
$prize-value-text-field-background: #eceef1;

//contract-success-payment-card-styles
$contract-success-payment-card-background: #44c27c;
$title-header-font-size: 28px;

//payment-success-card-styles
$breakup-fee-background: #6cd69bdb;

//apply-contract-input-styles
$apply-contract-input-field: #eceef1;

//Refresh-button-styles
$background-color-on-hover: #32253f