.flex-centering {
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.center-content {
  justify-content: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-horizontal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #0000001a;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
.MuiDataGrid-virtualScroller {
  border-top: 1px solid #0000001a;
}
.MuiDataGrid-row,
.MuiDataGrid-cell {
  border-bottom: 1px solid #0000001a !important;
}

.MuiTableCell-columnHeaders {
  border-bottom: 1px solid #0000001a;
}

.MuiTable-root {
  border: 1px solid #0000001a !important;
}
.MuiTableCell-root {
  border-top: 1px solid #0000001a !important;
  border-bottom: 1px solid #0000001a !important;
}
.MuiTableCell-head {
  font-weight: bold !important;
}
.MuiTableContainer-root {
  box-shadow: (0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px,
    rgb(0 0 0 / 12%) 0px 1px 3px;
}
.MuiTableBody-root {
  border-left: 1px solid #0000001a !important;
  border-right: 1px solid #0000001a !important;
}

::-webkit-scrollbar {
  width: 0.3em; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #7c8490;
}
