@import '../../../../styles/variables.scss';

.login-form {
  position: absolute;
  max-width: 450px;
  max-height: auto;
  left: 50%;
  top: 5%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);

  .contract-link-text-local-styles {
    text-transform: capitalize;
    width: 50%;
    margin: 0 100px;
    height: 60px;
  }

  .password-field {
    margin-top: 30px;

    .input-props-parent-div {
      display: flex;

      .visibility-icon-local-styles {
        position: relative;
        top: 12.5px;
      }
    }

    .input-props-parent-div>svg {
      color: $input-label-text-color !important;
      position: relative;
      top: 0;
    }
  }

  .header-input-field-div {
    margin: 16px 0 0 5px;

    .item-parent-div {
      width: 426px;

      .item-label-input {
        display: flex;
        justify-content: space-between;
        border: none;
        padding: 6px 0 6px 0;
        background-color: $item-label-input-background-color;
        white-space: normal;
        margin-bottom: 0;

        .link-position {
          float: right;
        }
      }

      .login-input-field>div>div {
        width: 100%;
        border-radius: 0px;
        color: $black-color !important;
        vertical-align: middle;
        background-color: $default-white-color !important;
      }

      .login-input-field>div>div>input {
        text-shadow: 0px 0px 1px $default-text-shadow-color;
        padding: 13px;
      }

      .error-text-component {
        margin: 0;
        background: $default-white-color;
        padding: 0 0 0 10px;
      }
    }

    .login-button-parent-div {
      width: 400px;
      margin-top: 40px;
    }

    .login-button-styles {
      background-color: $input-label-text-color;
      color: $default-white-color;
      border: none;
      border-radius: 0px;
      cursor: pointer;
      position: relative;
      width: 50%;
      left: 25%;
      font-weight: bold;
      height: 60px !important;
      margin: 25px 0 5px 0 !important;
      transition: all 0.5s;
      text-transform: capitalize;
    }

    .login-button-parent-div {
      width: 400px;
      margin-top: 15px;
    }

    .contract-portal-subtitle-div {
      width: 70%;
      vertical-align: middle;
      text-align: center;
      margin: 25px;
      margin-left: 50px;
    }

    .contract-portal-subtitle {
      font-size: 16px;
      text-align: center;
      color: #7c8490;
      font-weight: 600;
    }
  }

  .link-text-local-styles {
    height: 60px;
    left: 33%;
    width: 50%;
    position: relative;
    margin: 35px 0 3.5px 0;
    letter-spacing: 0.1rem !important;
  }

  .next-up-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 115px;
    width: 70vw;
    max-width: 340px;
    padding: 10px;
  }

  border: 0 !important;
  width: 450px !important;
}

.login-button-parent-div {
  width: 400px;
  margin-top: 15px;
}

.next-up-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 115px;
  width: 70vw;
  max-width: 340px;
  padding: 10px;
}