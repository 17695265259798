body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'SFProRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}

button,
input,
a,
p,
li,
select,
h2 {
  font-family: 'SFProRegular' !important;
}

code {
  font-family: 'SFProRegular';
}

div,
h1,
h2,
h3,
a,
p,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'SFProRegular';
  font-weight: 400;
  src: local('SFProRegular'),
    url(../assets/fonts/SFProDisplay/SFProDisplay-Regular.ttf)
      format('truetype');
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  font-weight: 400;
  src: local('SFProDisplay-Bold'),
    url(../assets/fonts/SFProDisplay/SFProDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SFProRegularItalic';
  font-weight: 400;
  src: local('SFProRegularItalic'),
    url(../assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.ttf)
      format('truetype');
}
